import { useState, ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {isMobile} from 'react-device-detect';
import { submitChatMessage, submitChatMessageError } from '../../redux/modules/game';
import { DefaultGenerics } from 'stream-chat';

import styled from '@emotion/styled';
import {
  Chat as ChatClient,
  Channel,
  MessageList,
  MessageInput,
  Window,
  Thread,
  ChatAutoComplete,
  useMessageInputContext,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import './Chat.css';
import { Button } from '../../components/buttons/Button';

import {curiousBlue, lightGrey} from '../../theme/vars';

// state
import {RootState} from '../../redux/rootReducer';

// environment
import {environment} from '../../environments/environment';

// components
import {SimpleChatMessage} from '../../components/chat/SimpleChatMessage';
import {ReactComponent as ChatPlane} from '../../assets/icons/chatPlane.svg';

// svgs
import {HomeTurfUser} from '../../types/user';
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes.constants';
import { TailSpin } from 'react-loader-spinner';

// background image
import ChatBackground from '../../assets/images/chat_background.png';
import useJoinedTeam from '../../hooks/useJoinedTeam';
import { trackEventData } from '../../utils/analytics';
import { SUBMIT_CHAT_MESSAGE } from '../../constants/analytics.constants';
import { AnyAction } from '@reduxjs/toolkit';
import useToast from '../../hooks/useToast';
import errorMessages from '../../constants/errorMessages';
import { BANNER_AD_CYCLE_TiME_MS, PROFANITY_ERROR } from '../../constants/Global.constants';
import { useChatClient } from '../../hooks/useChatClient';
import BannerAdImage from '../../components/image/BannerAdImage';
import { BannerAd } from '../../types/bannerAd';

type ChatHeaderTeamNameProps = {
  color?: string;
};

type MessageListContainerProps = {
  color?: string;
  backgroundImage?: string;
  children?: ReactNode;
  guestUser: boolean;
};

type ChatInputContainerProps = {
  color: string;
};

type ChatHeaderProps = {
  color: string;
};

type ChatContainerProps = {
  guestUser: boolean;
  isMobile: boolean;
}

const Container = styled.div<ChatContainerProps>`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  overflow: hidden;

  .str-chat-channel {
    height: calc(100% - 50px);
    .str-chat__container {
      height: 100%;

      .str-chat__avatar {
        display: none;
      }
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
`;

const Loading = () => {
  return (
    <LoadingContainer>
      <TailSpin color='#00BFFF' />
    </LoadingContainer>
  );
};

const ChatHeaderContainer = styled.button<ChatHeaderProps>`
  width: 100%;
  padding: 0.7rem 0 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #${({color}) => color};
  border-style: none;
  z-index: 5;
  border-bottom: 0.13rem solid #000;
`;

const BannerAdContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding:0;

  background-color: transparent;

  border: none;

  img { width: 100%; max-height: 53px; object-fit: contain;}
`;

const ChatHeader = styled.h1`
  color: #fff;
  font-family: Industry-Black;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  letter-spacing: -0.024rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

const ChatHeaderTeamName = styled.span<ChatHeaderTeamNameProps>`
  color: #${({color}) => color ?? 'FFF'};
  margin-right: 0.5rem;
  font-family: Industry-Black;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const MessageListContainer = styled.div<MessageListContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  background: center / cover no-repeat url(${({backgroundImage}) => backgroundImage}) !important;
  overflow: scroll;

  .str-chat__list,
  .messaging,
  .str-chat {
    background: none !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 !important;
  }

  .str-chat__ul {
    padding-top: 10px;
  }

  .str-chat__reverse-infinite-scroll {
    padding-top: 0 !important;
  }

  .str-chat__li:last-child {
    padding-bottom: 86px !important;
  }

  .str-chat__li--single {
    padding-bottom: unset;
    padding-top: 5px;
  }

  .str-chat__typing-indicator {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .str-chat__avatar-image {
    object-fit: cover !important;
  }
`;

const ChatInputContainer = styled.div<ChatInputContainerProps>`
  position: absolute;
  bottom: 0;
  background-color: #${({color}) => color};
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-top: 0.13rem solid #000;
  width: 100%;
  z-index: 4;

  form {
    flex: 1;
    display: flex;

    textarea {
      font-family: Roboto-Regular;
      flex: 1;
      border-radius: 0.63rem;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      border: 1px solid ${lightGrey};
      padding-left: 10px;
      font-size: 16px;

      &:focus {
        background-color: rgba(0, 0, 0, 0.7);
      }

      &::placeholder {
        color: #979797;
      }
    }

    button {
      width: 2rem;
      padding: 0 !important;
      background-color: ${curiousBlue};
      border: none;
      border-radius: 50%;
      background: none;
      overflow: hidden;
      margin: 0 5px;

      svg {
        width: 90%;
      }
    }
  }
`;

const CreateAccountContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({color}) => color};
`;

const Chat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.user);
  const {isInHomeStadium} = useSelector((state: RootState) => state.geolocation);
  const {gameChatChannelName, getstreamToken: chatToken} = useSelector(
    (state: RootState) => state.game.config,
  );
  const {gameId, submittingChatError} = useSelector((state: RootState) => state.game);

  const {
    color: teamColor,
    secondaryColor,
    name,
    bannerAds
  } = useSelector((state: RootState) => {
    return state.teams.activeTeam;
  });
  const {username, id} = userData || {} as HomeTurfUser;

  // @ts-ignore
  const [chatClientChannel, setChatClientChannel] = useState<Channel<DefaultGenerics> | null>(null);
  const [bannerAd, setBannerAd] = useState<BannerAd | null>(null);
  const joinedTeam = useJoinedTeam();
  const toast = useToast();
  const chatClient = useChatClient({
    apiKey: environment.streamChatAPIKey,
    user: {
      id,
      name: username || 'Guest',
    },
    tokenOrProvider: chatToken
  });
  const bannerRef = useRef<NodeJS.Timeout | undefined>();


  useEffect(() => {
    const connect = async() => {
      if (chatClient) {
        const channel = chatClient.channel('messaging', gameChatChannelName);
        await channel.watch;
        setChatClientChannel(channel);
      }
    }
    if (chatClient && !chatClientChannel && gameChatChannelName) {
      connect();
    } else if (!chatClient && chatClientChannel) {
      setChatClientChannel(null);
    }
  }, [chatClient, chatClientChannel, gameChatChannelName]);

  useEffect(() => {
    if (submittingChatError) {
      if ( submittingChatError.includes(PROFANITY_ERROR)) {
        toast.statusToast(errorMessages.submitChatMessageProfanityError, 'error');
      } else {
        toast.statusToast(errorMessages.submitChatMessageRequestError, 'error');
      }
      dispatch(submitChatMessageError(null) as unknown as AnyAction);
    }
  }, [dispatch, toast, submittingChatError]);

  useEffect(() => {
    const pickBannerAd = () => {
      const bannerAd = bannerAds[Math.floor(Math.random() * bannerAds.length)];
      setBannerAd(bannerAd);
    }
    if (bannerAds.length) {
      pickBannerAd();
      bannerRef.current && clearInterval(bannerRef.current);
      bannerRef.current = setInterval(() => {
        pickBannerAd();
      }, BANNER_AD_CYCLE_TiME_MS);
    }
    return () => {
      if (bannerRef.current) {
        clearInterval(bannerRef.current);
      }
    };
  }, [bannerAds]);

  const onClickCreateAccount = () => {
    if (gameId) {
      navigate(routes.smsAuth.replace(':gameId', gameId));
    }
  }

  const CustomMessageInput = () => {
    const {text, setText} = useMessageInputContext();

    const customHandleSubmit = (e: any) => {
      e.preventDefault();
      //handleSubmit(e, { isInHomeStadium });
      if (gameId && joinedTeam?.id && text !== '') {
        dispatch(submitChatMessage(gameId, joinedTeam.id, text, isInHomeStadium) as unknown as AnyAction);
        trackEventData(SUBMIT_CHAT_MESSAGE, {
          team_id: joinedTeam.id,
          game_id: gameId,
          user_id: id,
          isInHomeStadium,
        });
        setText('');
      }
    }

    return (
      <ChatInputContainer color={teamColor}>
        {/*<Avatar image={userData?.profilePicPath ?? defaultAvatar} size={50} />*/}
        <form
          onSubmit={customHandleSubmit}>
          <ChatAutoComplete
            handleSubmit={customHandleSubmit}
          />
          <button type="submit">
            <ChatPlane />
          </button>
        </form>
      </ChatInputContainer>
    );
  };

  return (
    <>
      {(!!chatClient && !!chatClientChannel) ?
        <Container guestUser={userData?.guestUser || false} isMobile={isMobile}>
          {bannerAd === null ? (<ChatHeaderContainer color={teamColor}>
              <ChatHeader>
                <ChatHeaderTeamName color={secondaryColor}>
                  {name}
                </ChatHeaderTeamName>
                CHAT
              </ChatHeader>
            </ChatHeaderContainer>) :
            <BannerAdContainer onClick={() => window.open(bannerAd?.banner_url, '_blank')}>
              <BannerAdImage path={bannerAd.banner_image_path} src={bannerAd.banner_image_url} alt={bannerAd.banner_name} />
            </BannerAdContainer>}
          <ChatClient client={chatClient} theme="str-chat__theme-dark">
            <Channel channel={chatClientChannel} Input={CustomMessageInput}>
              <Window>
                {/* <HTImage type={AssetType.Wordmark} teamId={teamId} altText={name} width="68px" /> */}
                <MessageContainer>
                  <MessageListContainer
                    guestUser={userData?.guestUser || false}
                    color={teamColor}
                    backgroundImage={ChatBackground}>
                    <MessageList
                      noGroupByUser={true}
                      Message={SimpleChatMessage}
                      disableDateSeparator={true}
                    />
                  </MessageListContainer>
                  {!userData?.guestUser && (<MessageInput />)}
                  {userData?.guestUser && (
                    <CreateAccountContainer color='#003087'>
                      <Button
                        type="button"
                        style={{ margin: '1rem 2rem' }}
                        onClick={onClickCreateAccount}
                      >
                        LOGIN TO CHAT
                      </Button>
                    </CreateAccountContainer>
                  )}
                </MessageContainer>
              </Window>
              <Thread />
            </Channel>
          </ChatClient>
        </Container> : <Loading /> }
    </>
  )
};

export default Chat;
