import {createSlice} from '@reduxjs/toolkit';
import {BASE_ROUTES, request} from '../../../api';
import {AppThunk} from '../../store';
import { Game } from '../../../types/game';

type TeamsState = {
  loading: boolean;
  teamsData: any[];
  nextGame: Game | null;
  loadingNextGame: boolean;
  loadingNextGameSuccess: boolean | null;
  loadingNextGameError: any | null;
  activeAppTeamId: any | null;
  activeTeam: any | null;
  activeTeamError: any | null;
  error: any | null;
};

const initialState: TeamsState = {
  loading: false,
  teamsData: [],
  nextGame: null,
  loadingNextGame: false,
  loadingNextGameSuccess: null,
  loadingNextGameError: null,
  activeAppTeamId: null,
  activeTeam: null,
  activeTeamError: null,
  error: null,
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    getTeamsRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getTeamsSuccess(state, action) {
      state.loading = false;
      state.teamsData = action.payload.teams;
    },
    getTeamsError(state, action) {
      state.loading = false;
      state.error = action.payload.error;
    },
    setActiveAppTeamId(state, action) {
      state.activeAppTeamId = action.payload;
    },
    setActiveTeam(state, action) {
      state.activeTeam = action.payload.activeTeam;
      state.activeTeamError = initialState.activeTeamError;
    },
    setActiveTeamError(state, action) {
      state.activeTeamError = action.payload;
    },
    getNextGameRequest(state) {
      state.loadingNextGame = true;
      state.loadingNextGameSuccess = null;
      state.loadingNextGameError = null;
    },
    getNextGameSuccess(state, action) {
      state.loadingNextGame = false;
      state.loadingNextGameSuccess = true;
      const {homeStadiumLat, homeStadiumLong, awayStadiumLat, awayStadiumLong, ...game} = action.payload.game;
      if (game && game.id) {
        state.nextGame = {
          ...game,
          stadiumCoordinates: {
            homeStadiumLat,
            homeStadiumLong,
            awayStadiumLat,
            awayStadiumLong
          }
        };
      } else {
        state.nextGame = null;
      }

    },
    getNextGameError(state, action) {
      state.loadingNextGame = false;
      state.loadingNextGameSuccess = false;
      state.nextGame = null;
      state.loadingNextGameError = action.payload.error;
    },
    updateNextGameFromPusher(state, action) {
      if (state.nextGame && state.nextGame.id === action.payload.data.id) {
        state.nextGame = {
          ...state.nextGame,
          homeGameState: action.payload.data.homeGameState,
          awayGameState: action.payload.data.awayGameState,
        };
      }
    },
    clearTeams: () => initialState,
  },
});

export const {
  getTeamsRequest,
  getTeamsSuccess,
  getTeamsError,
  setActiveAppTeamId,
  setActiveTeam,
  setActiveTeamError,
  getNextGameRequest,
  getNextGameSuccess,
  getNextGameError,
  clearTeams,
  updateNextGameFromPusher,
} = teamsSlice.actions;

const apiGetTeams = async () => {
  return request({
    route: BASE_ROUTES.TEAMS,
  });
};

const apiGetTeamSchedule = async (teamId: any) => {
  return request({
    route: BASE_ROUTES.GAME_SCHEDULE,
    pathParams: {teamId: teamId},
  });
};

export const getTeams = (activeTeamId: string): AppThunk => async (dispatch, getState) => {
  dispatch(getTeamsRequest());
  try {
    const teams = await apiGetTeams();

    dispatch(getTeamsSuccess({teams}));
    const activeTeam = teams.find(
      (team: {id: any}) => team.id === activeTeamId,
    );
    if (!activeTeam) {
      return dispatch(
        setActiveTeamError('No team found for the given native app team id'),
      );
    }
    dispatch(setActiveTeam({activeTeam}));
  } catch (err) {
    dispatch(getTeamsError((err as Error).message));
  }
};

export const getTeamGameSchedule =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(getNextGameRequest());
    try {
      const state = getState();
      const activeTeamId = state.teams.activeAppTeamId;
      const data = await apiGetTeamSchedule(activeTeamId);
      dispatch(getNextGameSuccess({game: data}));
    } catch (err) {
      dispatch(getNextGameError((err as Error).message));
    }
  };

export default teamsSlice.reducer;
